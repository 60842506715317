<template>
  <div class="static-page wp-page" @click="clicked" v-if="article" :key="key">
    <Breadcrumb :items="breadcrumb" />
    <v-container>
      <h1 v-if="article.title">{{ article.title }}</h1>
      <v-img v-if="image" :src="image" :alt="imageAlt" class="mb-4" />
      <v-runtime-template v-if="article" :template="template" />
    </v-container>
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";
import article from "~/mixins/article";
import clickHandler from "~/mixins/clickHandler";
import VRuntimeTemplate from "v-runtime-template";
import ServiceDiscovery from "@/components/categoryBlock/ServiceDiscovery.vue";
import warehouseMap from "@/components/category/warehouse-map.vue";
/* eslint-disable vue/no-unused-components */
import {
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VDialog,
  VContainer,
  VRow,
  VCol
} from "vuetify/lib";

export default {
  mixins: [article, clickHandler],

  components: {
    ServiceDiscovery,
    warehouseMap,
    Breadcrumb,
    VRuntimeTemplate,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    VDialog,
    VContainer,
    VRow,
    VCol
  },
  computed: {
    template() {
      if (this.article) {
        return `<div>${this.article.content}</div>`;
      } else {
        return null;
      }
    },
    image() {
      return this.article.refImageUrl || this.article.imageUrl;
    },
    imageAlt() {
      return this.article.title || this.article.title;
    }
  },

  mounted() {
    // this.reload(this.$route.params.pageName);
    this.article = this.$route.params.article;
  },
  watch: {
    "$route.params.pageName": function(pageName) {
      this.reload(pageName);
    }
  }
};
</script>
